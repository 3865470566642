import 'ol/ol.css';
import {Map, View} from 'ol';
import Layer from 'ol/layer/Layer';
import TileWMS from 'ol/source/TileWMS';
import ImageWMS from 'ol/source/ImageWMS';
import Stamen from 'ol/source/Stamen';
import {fromLonLat} from 'ol/proj';
import {Group as LayerGroup} from 'ol/layer';
import {Circle as CircleStyle, Fill, Stroke, Style} from 'ol/style';
import {LineString, Polygon} from 'ol/geom';
import {OSM, Vector as VectorSource} from 'ol/source';
import {Tile as TileLayer, Vector as VectorLayer} from 'ol/layer';
import {Image as ImageLayer} from 'ol/layer';
import {getArea, getLength} from 'ol/sphere';
import {unByKey} from 'ol/Observable'
import MousePosition from 'ol/control/MousePosition';
import {createStringXY} from 'ol/coordinate';
import {ScaleLine, defaults as defaultControls} from 'ol/control';
import {platformModifierKeyOnly} from 'ol/events/condition';
import {DragBox, Select} from 'ol/interaction';
import Chart from 'chart.js';
import 'chartjs-plugin-zoom';

const maltaCoord = [14.35, 35.85];
const maltaProj = fromLonLat(maltaCoord);

var base =  new TileLayer({
    source: new Stamen({
      layer: 'terrain-background',
    })});

/* 
/* 
* GLOBAL VARIABLES DEFINITION
* NOTE: layers need to be populated manualy after adding each new layer, they need to be added in the array
* The rest of the arrays are populated runtime from html attributes assigned to them.
*/
window.layerParams = ["CHL_MO_ME_FINAL", "CHL_MO_MAX_FINAL", "CHL_WE_ME_FINAL",
"CHL_WE_MAX_FINAL",  "TSM_MO_ME_FINAL", 
"TSM_MO_MAX_FINAL", 
"TSM_WE_ME_FINAL", "TSM_WE_MAX_FINAL"];
window.layers = [base];

window.sliders = [""];
window.values = [""];
window.ids = [""];

window.isSingleSelected = false;
window.selectedId = "";
window.disabledCls = " slider_disabled";
window.isAnalysisActive = false;
window.myChart = null;
window.MousePosition = null;

// GEOSERVER data
window.capemaltaURL = 'https://geoserver.capemalta.net/geoserver/WATERCOLOURS_MERGE/wms';
window.watercoloursPortalURL = 'https://watercolours-portal.capemalta.net/geoserver/Watercolours/wms';

// Map definition

var view = new View({
    center: maltaProj,  
    zoom: 7.00
  });

var map = new Map({
  target: 'map',
  layers: [base],
  view: view
});

// Event registration and implementation

window.onload = function(){
  populateLayers(); 
  populateInputValuesAndSliders();
  registerEvents();
  map.addControl(new ScaleLine({units: 'metric'}));
};

function populateLayers ()
{
 for (var i = 0; i < layerParams.length; i++) { 
  var layerID = layerParams[i];  
  var isMergeLayer = layerID.endsWith("Merge");
  var isFinalLayer = layerID.endsWith("FINAL");

  var geoDomain = isMergeLayer ? 'WATERCOLOURS_MERGE' : (isFinalLayer ? '' : 'Watercolours');
  geoDomain = isFinalLayer ? '' : geoDomain + ":";
  var layerURL = isMergeLayer ? capemaltaURL : watercoloursPortalURL;
  var newLayer =  new TileLayer({
              source: new TileWMS({
                  url: layerURL,
                  params: {'LAYERS': geoDomain + layerID,'Tiled':'TRUE'},
                  serverType: 'geoserver',
              crossOrigin: 'anonymous',
              })});
  if(newLayer !== null) {
    layers.push(newLayer);
  }
 }
}      

var mousePositionControl = new MousePosition({
  coordinateFormat: createStringXY(3),
  projection: 'EPSG:4326',
  // comment the following two lines to have the mouse position
  // be placed within the map.
  className: 'custom-mouse-position',
  target: document.getElementById('coord'),
  undefinedHTML: '&nbsp;',
});

map.addControl(mousePositionControl);

function populateInputValuesAndSliders () 
{
  var elementsToBeDisabled = $(".toggled_visibility");
  for (var i = 0; i < elementsToBeDisabled.length; i++) {
    var aElement = elementsToBeDisabled[i];
    aElement.disabled = true;
    

    //populating sliders and values
    if((aElement.className.indexOf("slider") !== -1) && sliders.indexOf(aElement.id) === -1) 
    {
      sliders.push(aElement.id);
      aElement.className = aElement.className.concat(disabledCls);
    } 
    else if ((aElement.className.indexOf("value") !== -1) && values.indexOf(aElement.id) === -1) 
    {
      values.push(aElement.id);
      aElement.hidden = true;
    }
  }

  // registering event on populated sliders and values
  for (var i = 1; i < values.length; i++) {
    // set initial value and slider state on checking the layer
    var sliderId = sliders[i]; 
    var valueId = values[i]; 

    var currentSliderEl = document.getElementById(sliderId); 
    var currentValueEl = document.getElementById(valueId); 
    currentValueEl.innerHTML = [currentSliderEl.value]; 

    var currentLayer = layers[i];
    currentLayer.getSource().updateParams({'DIM_MONTH': [currentSliderEl.value]});

  // registering on input event on slider change
    currentSliderEl.oninput = function onSliderChange (event) {
      var sliderId = event.target.name;
      var index = sliders.indexOf(sliderId);
      var currentLayer = layers[index];
      var currentValueEl = document.getElementById(values[index]);

      currentValueEl.innerHTML = [this.value];
      currentLayer.getSource().updateParams({'DIM_MONTH': [this.value]});

      hideInfo();
      showLowbar(false);
    }; 
  }

};


function registerEvents ()
{
  // EVENT: on change for checkbox inputs
  var checkboxInputs = $(".checkbox_input");
  for (var i = 0; i < checkboxInputs.length; i++) {
    var inputElement = checkboxInputs[i];
    inputElement.addEventListener('change', (event) => handleStateChange(event));
    inputElement.checked = false;

    // populating ids of layers (derived from checkbox inputs 1 checkbox - 1 layer)
    ids.push(inputElement.id);
  }

  //registering on click event for visualize buttons
  var visualizeButtons = $(".buttonVisualize");
  for (var i = 0; i < visualizeButtons.length; i++) {
  	var button = visualizeButtons[i];
  	button.addEventListener('click', (event) => handleDateChange(event));
  }

  // EVENT: on singleclick for layers
  map.on('singleclick', handleSingleClick);

// EVENT: on click for collapsing/expanding the sidebar chapters
var collapsibleElements = document.getElementsByClassName("collapsible");
for (var i = 0; i < collapsibleElements.length; i++) {
    collapsibleElements[i].addEventListener('click', (event) => toggleCollapse(event));
}

// other events, whatever you need can be put here
};

function handleSingleClick (evt) {
    if (!isSingleSelected) {
      return;
    }

  document.getElementById('info').innerHTML = 'Please wait...';
  var viewResolution = (view.getResolution());
  var currentLayer = getCurrentLayer();
  var source = currentLayer.getSource();
  var url = source.getFeatureInfoUrl(
    evt.coordinate,
    viewResolution,
    'EPSG:3857',
    {'INFO_FORMAT': 'application/json'}
  );
  if (url) {
       fetch(url)
      .then(function (response) { 
        var aText = response.text();
        return aText; 
      })
      .then(function (responseText) {

        var response = JSON.parse(responseText);
        var featuresReturned = response.features;
        var shownValue = "Selected area is unavailable";
        if(featuresReturned.length <= 0 || !featuresReturned[0].properties) 
        {
            showOutOfBounds("Selected area is unavailable");
            return;
        } 
        var properties = featuresReturned[0].properties;
        if(!properties)
        {
          console.log("No properties found! Cannot proceed to show value and timeseries");
        }
        var currentSlider = getSliderOfCurrentLayer();
        var sliderValue = "";
        var value = "";
        if(currentSlider && currentSlider.value)
        {
          sliderValue = currentSlider.value;
          value = properties["Band" + sliderValue];
        } else {
          // the layer is not connected to any slide-value pair, value fetched from props
          shownValue = "Value: " + Number.parseFloat(properties.GRAY_INDEX).toFixed(4);
          if(!shownValue || shownValue.toString().indexOf("999.0") !== -1) {
            showOutOfBounds("Selected area is unavailable");
            return;
          }
          showInfo(shownValue);
          return;
        } 
        
        if(!value || value.toString().indexOf("999.0") !== -1) {
            showOutOfBounds("Selected area is unavailable");
            return;
        }
        shownValue = "Value: " + Number.parseFloat(value).toFixed(4);

        var pixValues = getPixValuesFromJSON (response, currentSlider.max);
        if(pixValues.length !== 0) {
            drawChart(pixValues);
        }

        showInfo(shownValue);
      });
  }
 }

function toggleCollapse (event) {
    event.currentTarget.classList.toggle("active");
    var collapsedContent = event.currentTarget.nextElementSibling;
    if (collapsedContent.style.maxHeight){
      collapsedContent.style.maxHeight = null;
    } else {
      collapsedContent.style.maxHeight = collapsedContent.scrollHeight + "px";
    }
}

function performTSAnalysis(event) {
  if (!event || !event.currentTarget || !event.currentTarget.id || !event.currentTarget.id.includes("TS")) {
    return;
  }
  var buttonID = event.currentTarget.id;
  var index = ids.indexOf(buttonID);
  var targetLayer = layers[index];
  var layerID = ids[index];

  if (selectedId === "") {
    selectLayer (targetLayer, buttonID);  
  } else if (selectedId === layerID) {
    deselectCurrentlySelectedLayer();
  } else {
    deselectCurrentlySelectedLayer();
    selectLayer (targetLayer, buttonID); 
  }
}


function handleStateChange (event) 
{
  var currentID = "";
  if(event && event.currentTarget && event.currentTarget.attributes) {
      currentID = event.currentTarget.attributes.id.textContent; // the id of the clicked on input element
  }

  var index = ids.indexOf(currentID);
  var targetLayer = layers[index];
  var targetSliderId = sliders[index];
  var targetValueId = values[index];

  // the checked state after click true/false
  var isChecked = event.currentTarget.checked;
  // slider and value of the input element with the id
  var slider = document.getElementById(targetSliderId);
  var value = document.getElementById(targetValueId);

  if (isChecked && selectedId === "") {
    // checking something when nothing is checked 
    selectLayer (targetLayer, currentID);
    enableSliderAndValue (slider, value, false);
  } else if (!isChecked && (isSingleSelected === true) && (currentID === selectedId)){
    // unchecking the checked one when clicked on the same one that was checked
    map.removeLayer(targetLayer);
    enableSliderAndValue (slider, value, true);
    setSelectedNone();
  } else if(selectedId !== ""){
    // uncheking the previously checked one, and checking the newly checked one (those two are different)
    deselectCurrentlySelectedLayer();
    selectLayer (targetLayer, currentID);
    enableSliderAndValue (slider, value, false);
  }
};

function handleDateChange (event) {
    var datePickerID = "";
    if(event.target && event.target.id) {
    	datePickerID = event.target.id;
    } 
	var datePicker = document.getElementById(datePickerID + " datepicker");
	var dateString = datePicker.value;
  if(dateString === '')
  {
    datePicker.focus();
  }

	var month = dateString.substring(dateString.indexOf('-') + 1, dateString.indexOf('-') + 3);
    if(month.startsWith("0")) {
    	month = month.substring(1);
    }

	var layerURL = "https://geoserver.capemalta.net/geoserver/WATERCOLOURS_MERGE/wms";
    var newLayer =  new TileLayer({
              source: new TileWMS({
                  url: layerURL,
                  params: {'LAYERS': datePickerID,'Tiled':'TRUE'},
                  serverType: 'geoserver',
              crossOrigin: 'anonymous',
              })});
    newLayer.getSource().updateParams({'DIM_MONTH': [dateString]});
    if(newLayer) {
    	deselectCurrentlySelectedLayer();
    	layers.push(newLayer);
    	var newId = newLayer.values_.source.key_;
    	ids.push(newId);
    	selectedId = newId;
    	selectLayer(newLayer, newId);         
    } 
    newLayer.on('singleclick', handleSingleClick);
}

function enableSliderAndValue (slider, value, bEnabled) {
    if(!slider || !value) { // TS layer in question, so we don't have slider or value
      return;
    }

    slider.disabled = bEnabled;
    var className = "";
    if(bEnabled) {
      className = slider.className.indexOf(disabledCls) === -1 ? slider.className.concat(disabledCls) : slider.className;
    } else {
      className = slider.className.indexOf(disabledCls) !== -1 ? slider.className.replace(disabledCls,"") : slider.className;
    }
    slider.className = className;
    value.hidden = bEnabled;
}

function selectLayer(targetLayer, targetID){
    map.addLayer(targetLayer); 
    isSingleSelected = true;
    selectedId = targetID;  
    if(myChart) {
      showLowbar(false);
      myChart.destroy();
      showLegend(true);   
    }
}

function showLowbar (shouldBeShown) {
  var lowbar = document.getElementById("lowbar");
  if(shouldBeShown) {
    $("#lowbar").show();
    return;
  }
  $("#lowbar").hide();
} 

function deselectCurrentlySelectedLayer () {
    var index = ids.indexOf(selectedId);
    if(index === 0) { // do not deselect BASE layer
    	return;
    }
    if(selectedId.indexOf("LAYERS-") != -1) 
    {
      var layer = layers[index];
      map.removeLayer(layer);
      layers.splice(index, 1);
      ids.splice(index, 1);
      // RISKY TEST AGAIN WHAT IS THIS BLOCK - I ADDED SET SELECTED NONE FOR DATE CHANGE
      setSelectedNone();
      return;
    }
    var layer = layers[index];
    map.removeLayer(layer);

    var sliderId = sliders[index];
    var valueId = values[index];
    var slider = document.getElementById(sliderId);
    var value = document.getElementById(valueId);
    enableSliderAndValue (slider, value, true);

    setSelectedNone();
    if(sliderId && valueId) {
      var checkboxId = ids[index];
      var checkbox = document.getElementById(checkboxId);
      checkbox.checked = false;
    }
};

function setSelectedNone () {
    isSingleSelected = false;
    hideInfo();
    showLowbar(false);
    selectedId = "";
}

// helper function, returns base layer if no layer is selected
function getCurrentLayer() {
  var currentID = ids.indexOf(selectedId);
  var currentLayer = layers[currentID];
  return currentLayer;
};

function getIndexOfCurrentLayer() {
  return ids.indexOf(selectedId);
}

function getSliderOfCurrentLayer() {
  return document.getElementById(sliders[getIndexOfCurrentLayer()]); 
}

function hideInfo () {
  var infoElement = document.getElementById('info');
  infoElement.innerHTML = "";
  infoElement.style.opacity = "0";
}

function showInfo (message) {
    var infoElement = document.getElementById('info');
    infoElement.innerHTML = message;
    infoElement.style.opacity = "1";
}

function showOutOfBounds (message) {
    showInfo(message);
    showLowbar(false);
}

function getPixValuesFromJSON (jsonData, expectedRange) {
        var pixValues = Object.values(jsonData.features[0].properties);
       
        if(pixValues.length === 0) {
          showOutOfBounds("No values detected");
        } 
        var numericPixValues = pixValues.filter(Number)  
        return numericPixValues;
}

function showLegend (expectedShown) {
  var legend = document.getElementById("legend");
  legend.style.opacity = expectedShown ? "1" : "0";
}

function drawChart (pixValues) {
  hideInfo();
  showLowbar(true);

  // bug in chart.js, this prevents onHover from previously shown charts 
 document.getElementById("chartContainer").innerHTML = '<canvas id="myChart" width = "900" height = "500" ></canvas>';

  var labelSequence = [];
  for (var i = 0; i < pixValues.length; i++) {
    labelSequence[i] = i + 1;
  }
  var ctx = document.getElementById('myChart').getContext('2d');
  myChart = new Chart(ctx, {
    type: 'line',
    data: {
        labels: labelSequence,
        datasets: [{
            label: 'Quantity',
            data: pixValues,
            borderColor:['#3399FF'],
            backgroundColor:'rgba(0, 0, 0, 0)',
            borderWidth: 2
        }],

    },
    options: {
      responsive : true,
      maintainAspectRatio : false,
        scales: {
            yAxes: [{
                ticks: {
                    beginAtZero: false
                }
            }]
            
        },
        plugins: {
            zoom: {
                // Container for pan options
                pan: {
                    // Boolean to enable panning
                    enabled: true,

                    // Panning directions. Remove the appropriate direction to disable 
                    // Eg. 'y' would only allow panning in the y direction
                    mode: 'xy'
                },
                rangeMin: {
        // Format of min pan range depends on scale type
        x: 0,
        y: 0
      },
      rangeMax: {
        // Format of max pan range depends on scale type
        x: null,
        y: null
      },

                // Container for zoom options
                zoom: {
                    // Boolean to enable zooming
                    enabled: true,

                    // Zooming directions. Remove the appropriate direction to disable 
                    // Eg. 'y' would only allow zooming in the y direction
                    mode: 'xy',
                }
            }
        }

    }
});
}
